import React from "react"
import { Link } from "gatsby"

import styles from "./wrapping-list.module.css"

const defaultGroupComponent = ({ group, items, slugPrefix }) => (
  <>
    <div id={group.toUpperCase()} className="letter">
      {group.toUpperCase()}
    </div>
    <ul className="eq-list">
      {items[group].map(listedItem => (
        <li key={listedItem.name}>
        <Link
          className="manufacturer-list"
          to={`${slugPrefix}${listedItem.slug}`}
          state={{name: listedItem.name}}
        >
          {listedItem.name}
        </Link>
        </li>
      ))}
    </ul>
  </>
)

const WrappingList = props => {
  const { containerClassName, data, GroupComponent, IndexComponent, itemClassName, slugPrefix } = props;
  const Group = GroupComponent || defaultGroupComponent;

  var alphabet = {
    "*": [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
    0: [],
    a: [],
    b: [],
    c: [],
    d: [],
    e: [],
    f: [],
    g: [],
    h: [],
    i: [],
    j: [],
    k: [],
    l: [],
    m: [],
    n: [],
    o: [],
    p: [],
    q: [],
    r: [],
    s: [],
    t: [],
    u: [],
    v: [],
    w: [],
    x: [],
    y: [],
    z: [],
  }

  //get the manufacturers into their buckets
  data.forEach(listedItem => {
    var firstLetter = listedItem.name[0].toLowerCase();
    if (alphabet[firstLetter]) {
      alphabet[firstLetter].push(listedItem);
    }
    else
      alphabet["*"].push(listedItem);
  })

  const keys = Object.keys(alphabet).filter(k => alphabet[k].length > 0);
  let sections = [keys];
  let wrapperClasses = 'row';
  let liClasses = itemClassName || styles.flexItem;
  if (data.length < 120) {
    const n = Math.floor(data.length / 5);
    const groups = [];
    let group = [];
    let freeSlots = n;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      group.push(key);
      freeSlots -= alphabet[key].length;
      if (freeSlots <= 0)  {
        groups.push(group);
        group = [];
        freeSlots = n;
      }
    }
    if (group.length > 0) {
      groups.push(group);
    }
    sections = groups;
    wrapperClasses += ' xsmall-up-2 small-up-3 medium-up-4';
    if (sections.length > 4) {
      wrapperClasses += ' large-up-5';
    }
    liClasses = itemClassName || '';
  }

  return (
    <div className={wrapperClasses}>
      {IndexComponent && (
        <div className="column">
          <IndexComponent items={keys} />
        </div>
      )}

      {sections.map((section, i) => (
        <div key={`section-${i}`} className="column">
          <ul className={`${containerClassName || ''} ${styles.flexContainer}`}>

            {section.map(group => (
              <>
                <li className={liClasses} key={group}>
                  <Group group={group} items={alphabet} slugPrefix={slugPrefix} />
                </li>
              </>
            ))}

          </ul>
        </div>
      ))}
    </div>
  )
}

export default WrappingList
